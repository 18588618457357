import React from "react";
import styled from "styled-components";
import {
  colors,
  breakpoints,
  Section,
  Container,
  H3,
  P,
  LinkButton,
} from "styles";
import { Seo, Header, ProductsBanner } from "components";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Pharmacy, CEMark, ISCP } from "assets/svgs";

const ProductsPage = ({ data }) => {
  return (
    <>
      <Seo title="Products" />
      <Header
        data={data}
        title="Four key reasons to trust ThermaCare® heat wraps."
        content="<ol><li>ThermaCare® heat wraps <strong>generate real penetrating and constant heat</strong> to the affected area of the body.</li><li>ThermaCare® heat wraps are <strong>100% drug-free</strong>.</li><li>ThermaCare® <strong>improves flexibility</strong> with muscles and joints.</li><li>ThermaCare® Provides up to <strong>16 hours pain relief</strong> (clinically proven).</li></ol>"
      />
      <Section $bg="transparent">
        <ProductsContainer>
          <ProductCard>
            <ProductImg>
              <Pharmacy />
              <ISCP className="iscp-logo" />
              <CEMark />
              <GatsbyImage
                image={getImage(data.backPainRelief)}
                alt="ThermaCare® Lower back & hip"
                objectFit="contain"
                objectPosition="center"
              />
            </ProductImg>
            <ProductDetails>
              <H3>
                ThermaCare®
                <br /> Lower back & hip
              </H3>
              <P>
                Therapeutic muscle penetrating heat wraps provides up to 16
                hours of pain relief for lower back and hip
              </P>
              <LinkButton
                to="/products/thermacare-lower-back-and-hip"
                style={{ width: "100%", fontSize: "14px" }}
              >
                View ThermaCare® for Lower back and hip
              </LinkButton>
            </ProductDetails>
          </ProductCard>
          <ProductCard>
            <ProductImg>
              <Pharmacy />
              <ISCP className="iscp-logo" />
              <CEMark />
              <GatsbyImage
                image={getImage(data.neckPainRelief)}
                alt="ThermaCare® Neck, shoulder and wrist"
                objectFit="contain"
                objectPosition="center"
              />
            </ProductImg>
            <ProductDetails>
              <H3>
                ThermaCare®
                <br /> Neck, shoulder and wrist
              </H3>
              <P>
                Therapeutic muscle penetrating heat with long lasting targeted
                pain relief for up to 16 hours for neck, shoulder and wrist.
              </P>
              <LinkButton
                to="/products/thermacare-neck-and-shoulders"
                style={{ width: "100%", fontSize: "14px" }}
              >
                View ThermaCare® for Neck, Shoulder and Wrist
              </LinkButton>
            </ProductDetails>
          </ProductCard>
        </ProductsContainer>
      </Section>
    </>
  );
};

const ProductDetails = styled.div`
  background: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 1.5rem;
  ${P} {
    margin-bottom: 3rem;
  }
  ${LinkButton} {
    justify-self: flex-end;
    line-height: 1rem;
    text-align: center;
    margin: auto 0 0 0;
  }
`;

const ProductImg = styled.div`
  height: 380px;
  padding: 2rem;
  position: relative;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
  }
  svg {
    position: absolute;
  }
  svg:first-of-type {
    height: auto;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
    z-index: 2;
  }
  .iscp-logo {
    left: 1rem;
    bottom: 0.5rem;
    max-width: 6.5rem;
    z-index: 3;
  }
  svg:last-of-type {
    right: 1rem;
    bottom: 0.5rem;
    width: 36px;
  }

  ${breakpoints.sm} {
    svg:first-of-type {
      right: 1.5rem;
      top: 2rem;
    }
    .iscp-logo {
      left: 1.5rem;
      bottom: 0.75rem;
    }
    svg:last-of-type {
      right: 1.5rem;
      bottom: 0.75rem;
    }
  }
  ${breakpoints.lg} {
    padding: 3rem 2rem;
    svg:first-of-type {
      right: 2rem;
      top: 2rem;
      width: 4rem;
    }
    .iscp-logo {
      left: 2rem;
      bottom: 1.5rem;
    }
    svg:last-of-type {
      right: 2rem;
      bottom: 1.5rem;
    }
  }
  ${breakpoints.xl} {
    .iscp-logo {
      max-width: 7rem;
    }
  }
`;

const ProductCard = styled.article`
  align-items: flex-start;
  background: white;
  box-shadow: 4px 4px 16px 3px rgba(84, 15, 22, 0.25);
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${breakpoints.sm} {
    grid-column: 2/6;
  }
  ${breakpoints.lg} {
    &:first-child {
      grid-column: 2/7;
    }
    &:last-child {
      grid-column: 7/12;
    }
  }
`;

const ProductsContainer = styled(Container)`
  background: transparent;
  grid-gap: 3rem;
  margin-top: -5rem;
  position: relative;
  z-index: 1;
  ${breakpoints.sm} {
    margin-top: -10rem;
  }
`;

export const pageQuery = graphql`
  query {
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    backPainRelief: file(relativePath: { eq: "back-pain-relief.png" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    neckPainRelief: file(relativePath: { eq: "neck-pain-relief.png" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;

export default ProductsPage;
